<template>
  <v-container v-if="vueClassificacoes">
    <v-row>
      <v-col cols="12">
        <PageToolbar
          :title="$tc('pages.classificacao', 2)"
          icon="bookmark"
          dark
        />
      </v-col>

      <v-col cols="12">
        <ListaClassificacoes :key="componentKey" />
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="290">
      <v-card :loading="loading" :disabled="loading">
        <v-card-title class="subtitle-1">{{
          $tc("global.nova") + " " + $tc("pages.classificacao")
        }}</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="classificacao.descricao"
            :label="$tc('global.descricao')"
            dense
            hide-details
          ></v-text-field>
          <v-text-field
            v-model="classificacao.icone"
            :label="$tc('class.icone')"
            dense
            hide-details
            class="mt-4"
          ></v-text-field>
          <v-color-picker
            v-model="colorPicker"
            :update:color="(classificacao.cor = colorPicker.hex)"
            hide-inputs
            hide-mode-switch
            flat
            class="mt-4"
          ></v-color-picker>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="anchors" text @click="dialog = false">{{
            $tc("botoes.cancelar")
          }}</v-btn>
          <v-btn color="anchors" text @click="createClassificacao">{{
            $tc("botoes.criar")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      v-if="vueAddClassififcacao"
      @click="dialog = !dialog"
      fixed
      right
      bottom
      fab
      dark
      color="buttons"
      :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import { postClassificacoes } from "@/services/api/classificacoes.api.js";
import { mapGetters } from "vuex";

export default {
  name: "Classificacoes",
  components: {
    ListaClassificacoes: () => import("./components/ListaClassificacoes.vue"),
  },
  data: () => ({
    componentKey: 0,
    classificacao: {},
    colorPicker: {},
    loading: false,
    dialog: false,
  }),
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vueClassificacoes() {
      return this.getAccess("vueClassificacoes", "menu");
    },
    vueAddClassififcacao() {
      return this.getAccess("vueClassificacao", "adicionar");
    },
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    createClassificacao() {
      this.$Progress.start();
      this.loading = true;
      postClassificacoes(this.classificacao)
        .then((response) => {
          if (response.status === 201) {
            this.$toast.success(this.$tc("msg.criadosucesso"));
            this.classificacao = {};
            this.forceRerender();
            this.dialog = false;
            this.loading = false;
            this.$Progress.finish();
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$Progress.fail();
        });
    },
  },
  mounted() {},
  created() {},
};
</script>

<style></style>
